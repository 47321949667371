<div *ngIf="viewInitialized && pageDetails?.title" class="panel-header">
    <h1 class="panel-title">
        <a class="hover-link" *ngIf="onOpen; else plain" (click)="open()">{{pageDetails.title}}</a>
        <ng-template #plain>{{pageDetails.title}}</ng-template>
        <span class="panel-title-info" *ngIf="pageDetails.info">{{pageDetails.info}}</span>
    </h1>
    <div class="add-on">
        <ng-container *ngTemplateOutlet="componentIcons"></ng-container>
        <ng-container *ngFor="let i of actions">
            <ng-container *ngxPermissionsOnly="i.permissions">
                <app-action-icon *ngIf="!i.shouldShowAction || (i.shouldShowAction(data) | async)"
                                 [icon]="i.icon"
                                 size="lg"
                                 [active]="i.active && (i.active(data) | async)"
                                 (click)="i.callback(data)"
                                 [tooltip]="i.caption">
                </app-action-icon>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="panel-body">
    <ng-container #vc></ng-container>
</div>
<div *ngIf="onOpen" class="text-primary hover-link panel-link" (click)="open()">
    {{openDetailsText}}
    <fal i="arrow-right" class="ml-1"></fal>
</div>
