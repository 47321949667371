import {
    AfterViewInit,
    Compiler,
    Component,
    ComponentRef,
    HostBinding,
    Injector,
    Input,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {UnsubscribeComponent} from '../../../../../@core/fc-component';
import {PageDetails, WithPageDetails} from '../../../../@slide-panel-preview/patient-details/patient-details.component';
import {HoverAction} from '../../hover-action.interface';
import {takeUntil} from 'rxjs/operators';
import {flyInTrigger} from '../../../../../@theme/animations';
import {createComponentFromOptions} from '../../../../../utils/component.utils';

@Component({
    selector: 'app-preview',
    host: {'class': 'preview-bubble'},
    templateUrl: './preview.component.html',
    animations: [flyInTrigger],
})
export class PreviewComponent<T = any> extends UnsubscribeComponent implements AfterViewInit {
    @HostBinding('@flyIn') visible = true;

    @Input() component: Type<WithPageDetails>;
    @Input() loadComponent?: () => Promise<Type<WithPageDetails>>;
    @Input() loadModule?: () => Promise<Type<any>>;
    @Input() template: TemplateRef<any>;
    @Input() data: T;
    @Input() actions: HoverAction[];
    @HostBinding('class.has-open')
    @Input() onOpen: (data: T) => void;
    @Input() openDetailsText = 'Open Details';
    @HostBinding('class.scrollable')
    @Input() scrollable = false;
    @ViewChild('vc', {read: ViewContainerRef, static: true}) viewContainer: ViewContainerRef;

    componentRef: ComponentRef<any>;
    viewInitialized = false;
    pageDetails: PageDetails;

    constructor(public injector: Injector,
                public compiler: Compiler) {
        super();
    }

    async ngAfterViewInit() {
        if (this.template && this.template instanceof TemplateRef) {
            setTimeout(() => this.viewContainer.createEmbeddedView(this.template, 0));
        } else if (this.component || this.loadComponent) {
            this.componentRef = await createComponentFromOptions(this);

            const instance: WithPageDetails = this.componentRef.instance;
            instance.data = this.data;
            instance.hasHeading = false;
            instance.preview = true;

            const initPage = (pageDetails: PageDetails = {
                title: instance.pageTitle,
                patientId: instance.patientId,
                info: instance.pageInfo,
            }) => {
                this.pageDetails = pageDetails;
                this.componentRef.changeDetectorRef.markForCheck();
            };

            if (this.componentRef.instance.pageDetails$) {
                this.componentRef.instance.pageDetails$.pipe(takeUntil(this.destroy$)).subscribe(x => initPage(x));
            } else {
                initPage();
            }
        }
        setTimeout(() => {
            this.viewInitialized = true;
            this.componentRef.changeDetectorRef.markForCheck();
        });
    }

    get componentIcons(): TemplateRef<any> {
        return this.componentRef?.instance.panelIcons || null;
    }

    compOnDestroy() {
        this.componentRef?.destroy();
    }

    open() {
        this.onOpen(this.data);
        this.componentRef?.destroy();
    }
}
