import {Directive, Input, Type} from '@angular/core';
import {HoverComponentDirective} from './hover-component.directive';
import {PreviewComponent} from '../components/preview/preview.component';
import {HoverAction} from '../hover-action.interface';
import {setIfDefined} from '../../../../utils/type.utils';

export interface HoverPreviewOptions<T = any> {
    component: Type<any>;
    data?: T;
    action?: () => void;
    actions?: HoverAction[];
    openDetailsText?: string;
    scrollable?: boolean;
    loadComponent?: () => Promise<Type<any>>;
    loadModule?: () => Promise<Type<any>>;
}

@Directive({
    selector: '[hoverPreview]',
})
export class HoverPreviewDirective<T = any> extends HoverComponentDirective<T> {
    @Input() hoverPreview: HoverPreviewOptions<T>;
    component: Type<PreviewComponent<T>> = PreviewComponent;
    previewComponent?: Type<any>;
    loadPreviewComponent?: () => Promise<Type<any>>;
    loadPreviewModule?: () => Promise<Type<any>>;
    action: () => void;
    actions: HoverAction[];
    openDetailsText: string;
    scrollable: boolean;

    createComponent() {
        if (this.hoverPreview) {
            Object.entries(this.hoverPreview).forEach(([key, val]) => {
                if (key === 'component') {
                    this.previewComponent = <Type<any>>val;
                } else if (key === 'loadComponent') {
                    this.loadPreviewComponent = val;
                } else if (key === 'loadModule') {
                    this.loadPreviewModule = val;
                } else {
                    this[key] = val;
                }
            });
        }

        if (!this.previewComponent && !this.loadPreviewComponent) return;

        super.createComponent(() => {
            const instance: PreviewComponent = this.componentRef.instance;
            instance.component = this.previewComponent;
            instance.loadComponent = this.loadPreviewComponent;
            instance.loadModule = this.loadPreviewModule;
            setIfDefined(instance, 'onOpen', this.action);
            setIfDefined(instance, 'actions', this.actions);
            setIfDefined(instance, 'openDetailsText', this.openDetailsText);
            setIfDefined(instance, 'scrollable', this.scrollable);
        });
    }
}
